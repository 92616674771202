import React, {useContext} from 'react';
import { Datepicker, Box } from "@flexisaf/flexibull2";

import { SchemaContext } from '../container'
import { InputError, InputWrapper } from '../input-wrapper';
import { formatApplicantDate } from '../../utils/helpers';

const DateInput = (props) => {
    const { disabledFields, errorFields, fieldValue, updateFieldValue} = useContext(SchemaContext)

    const isValidDate = (d) => d instanceof Date && !isNaN(d);

    const Re = (val) => {
      if (disabledFields.includes(props.keyField)) return;
      if (isValidDate(val))
        updateFieldValue(props.keyField, val)
    }

  return (
    <Box margin="23px 0px 0px 0px">
      <Datepicker
        {...props.props}
        onChange={Re}
        format="dd MMM yyyy"
        block
        name={props.keyField}
        value={fieldValue?.[props.keyField] ? new Date(formatApplicantDate(fieldValue?.[props.keyField])) : null}
        label={props.props.label}
        maxDate={props.props.endDate ? new Date(props.props.endDate) : new Date('1/1/2100')}
        minDate={props.props.startDate ? new Date(props.props.startDate) : new Date('1/1/1900')}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        showLeadingZeros={true}
        disabled={disabledFields.includes(props.keyField)}
      />
      <InputWrapper {...props}>
        <InputError>
          {errorFields[props.keyField]}
        </InputError>
      </InputWrapper>
    </Box>
  )
}
export default DateInput;
