import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { Box, Button } from "@flexisaf/flexibull2";
import { v4 as uuidv4 } from "uuid";

import { Col } from "./col";
import { SchemaContext } from "./container";
import { getFieldKey } from "../utils/helpers";

export const RowWrapper = styled("div").attrs({ className: "flex-render-row" })`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 1fr);
  grid-gap: 10px;
  align-items: start;
  padding: 10px 0;
  margin-top: 20px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;

    & > div {
      padding: 10px 0;
    }
  }
`;

export const RowWrapperDuplicate = styled("div").attrs({
  className: "flex-render-row",
})`
  display: grid;
  grid-template-columns: ${(props) => getColumnStructure(props.cols)};
  grid-gap: 10px;
  align-items: start;
  padding: 10px 0;
  margin-top: 20px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;

    & > div {
      padding: 10px 0;
    }
  }
`;

const getColumnStructure = (len) => {
  const colStructure = Array.from(Array(len)).map(() => "2fr");
  colStructure.push("1fr");
  return colStructure.join(" ");
};

const ColWrapper = (colProps) => (
  <Col {...colProps} keyField={getFieldKey(colProps)} />
);

export const Row = (props) => {
  const { duplicateFields, setDuplicateFields } = useContext(SchemaContext);
  const [colSchemas, setColSchemas] = useState([]);
  let {
    cols,
    id: rowId,
    sectionId,
    duplicateNumber,
    canDuplicate,
    addRowAlias,
    maxDuplicate,
  } = props;
  if (cols) {
    cols = !Array.isArray(cols) ? [cols] : cols;
  }

  useEffect(() => {
    const cls = !Array.isArray(cols) ? [cols] : cols;
    const duplicateIds =
      duplicateFields?.rows?.[`${duplicateNumber}-${rowId}`] || [];
    const newRows = [
      { id: rowId, cols: cls },
      ...duplicateIds.map((id) => ({ id, cols: [...cls] })),
    ];
    setColSchemas(newRows);
  }, [duplicateFields, cols]);

  const handleAddEntry = () => {
    const duplicateFieldsCopy = { ...duplicateFields };
    duplicateFieldsCopy.rows[`${duplicateNumber}-${rowId}`] = [
      ...(duplicateFieldsCopy?.rows?.[`${duplicateNumber}-${rowId}`] || []),
      uuidv4(),
    ];
    setDuplicateFields(duplicateFieldsCopy);
  };

  const deleteEntryRow = (r) => {
    const duplicateFieldsCopy = { ...duplicateFields };
    duplicateFieldsCopy.rows[`${duplicateNumber}-${rowId}`] = (
      duplicateFieldsCopy?.rows?.[`${duplicateNumber}-${rowId}`] || []
    ).filter((id) => id !== r.id);
    setDuplicateFields(duplicateFieldsCopy);
  };

  return (
    <Fragment>
      {colSchemas.map((r, index) => (
        <Fragment key={index}>
          {index > 0 ? (
            <RowWrapperDuplicate key={index} cols={cols.length}>
              <Fragment key={index}>
                {r.cols.map((col, j) => (
                  <ColWrapper
                    key={j}
                    duplicateSectionNumber={duplicateNumber}
                    duplicateRowNumber={r.id}
                    sectionId={sectionId}
                    rowId={rowId}
                    {...col}
                  />
                ))}
                <Box align="right" margin="23px 0 0 0">
                  <Button
                    pale
                    height={42}
                    style={{ width: '50%' }}
                    onClick={() => deleteEntryRow(r)}
                    color="#EE1B87"
                    fontColor="#EE1B87"
                    font="16px"
                    icon={<i className="flexibull-cancel"></i>}
                  />
                </Box>
              </Fragment>
            </RowWrapperDuplicate>
          ) : (r.cols.length === 1 && r.cols[0]?.props?.hide) ? (
            <></>
          ) : (
            <RowWrapper key={index} cols={cols.length}>
              <Fragment key={index}>
                {r.cols.map((col, j) => (
                  <ColWrapper
                    key={j}
                    duplicateSectionNumber={duplicateNumber}
                    duplicateRowNumber={r.id}
                    sectionId={sectionId}
                    rowId={rowId}
                    {...col}
                  />
                ))}
              </Fragment>
            </RowWrapper>
          )}
        </Fragment>
      ))}
      {canDuplicate &&
        colSchemas?.length > 0 &&
        colSchemas.length < (+maxDuplicate > 0 ? maxDuplicate : Infinity) && (
          <Box align="right">
            <Button
              height={30}
              onClick={handleAddEntry}
              pale
              iconRight={<i className="flexibull-plus" />}
            >
              {addRowAlias || "Add Row"}
            </Button>
          </Box>
        )}
    </Fragment>
  );
};
