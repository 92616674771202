import React from "react";
import styled from "styled-components";
import { TooltipWrapper } from "./TooltipWrapper";
import Input from "./elements/input";
import Phone from "./elements/Phone";
import DateInput from "./elements/date";
import { Selector } from "./elements/select";
import { Checkbox } from "./elements/checkbox";
import { Switch } from "./elements/switch";
import { TextArea } from "./elements/textarea";
import { Radio } from "./elements/radio";
import Uploader from "./elements/upload";
import Editor from "./elements/wysiwyg";
import { WebcamCapture } from "./elements/webcam";
import Lookup from "./elements/lookup";
import StateLGA from "./elements/state-lga";
import Location from "./elements/location";
import SingeDate from "./elements/single-date";
export const ColWrapper = styled("div").attrs({
  className: "flex-render-col",
})``;

export const Col = (prop) => {
  let { type, element, props } = prop;
  return (
    <TooltipWrapper {...prop}>
      <ColWrapper>
        {element === "input" && type == "plain" && <Input {...prop} type="text" />}
        {element === "input" && type == "number" && <Input {...prop} />}
        {element === "input" && type == "tel" && <Phone {...prop} />}
        {element === "input" && type == "email" && <Input {...prop} />}
        {element === "input" && type == "date" && <DateInput {...prop} />}
        {element === "input" && type == "single-date" && <SingeDate {...prop} />}
        {element === "location" && <Location {...prop} />}
        {element === "lookup" &&
          prop?.props?.source === "nigeria-states-lga" && (
            <StateLGA {...prop} />
          )}
        {element === "lookup" &&
          prop?.props?.source !== "nigeria-states-lga" && <Lookup {...prop} />}
        {element === "wysiwyg" && <Editor {...prop} />}
        {element === "select" && <Selector {...prop} />}
        {element === "checkbox" && <Checkbox {...prop} />}
        {element === "switch" && <Switch {...prop} />}
        {element === "radio" && <Radio {...prop} />}
        {element === "input" && type == "essay" && <TextArea {...prop} />}
        {element === "file" && type == "file" && <Uploader {...prop} />}
        {element === "camera" && type == "camera" && !props?.hide && <WebcamCapture {...prop} />}
      </ColWrapper>
    </TooltipWrapper>
  );
};
