import moment from "moment";

export const getFieldKey = (props) => {
  const myArr = [];
  if (isNaN(props.duplicateSectionNumber))
    myArr.push(props.duplicateSectionNumber);
  if (isNaN(props.duplicateRowNumber)) myArr.push(props.duplicateRowNumber);
  myArr.push(props.fieldId);
  return myArr.filter((a) => a).join("-");
};

export const formatApplicantDate = (date) => {
  var dateMomentObject, dateObject;
  const dateIsValid =
    moment(date).format("MM/DD/YYYY") === "Invalid date" ? false : true;
  if (!dateIsValid) {
    dateMomentObject = moment(date, "DD/MM/YYYY");
    dateObject = dateMomentObject.toDate();
  } else {
    dateObject = moment(date).toDate();
  }

  if (Object.prototype.toString.call(dateObject) === "[object Date]") {
    if (isNaN(dateObject.getTime())) {
      return date;
    } else {
      return dateObject.toString();
    }
  } else {
    return date;
  }
};
