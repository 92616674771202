import React, { useContext, useState } from "react";
import { Box, Button, Spacer, Notify, Text } from "@flexisaf/flexibull2";
import styled from "styled-components";
import Webcam from "react-webcam";
import ImageCropper from '../ImageCroper/FlexiCropper';
import { SchemaContext } from "../container";
import { InputError, InputWrapper } from "../input-wrapper";

const CamBox = styled.div`
  max-width: 340px;
  margin: 0 auto;
  border-radius: 5px;
  background: white;
  background: linear-gradient(0deg, #f5f5f5, #fff);
  text-align: center;
  border: 1px solid #7d8f9b;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  .preview-region img{
    max-width: 300px;
  }
  @media (max-width: 600px) {
    max-width: calc(100vw - 100px);

    .preview-region img{
      max-width: calc(100vw - 120px);
    }
  }

  .preview-region {
    min-height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .capture-region {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #7d8f9b;
    min-height: 260px;
    video {
      border-radius: 5px;
      overflow: hidden;
    }
  }

  svg {
    width: 324px;
    stroke-width: 0;
    margin-bottom: 1px;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 1.5;
    .cam-circler {
      fill: #c2ddf8;
    }
    .cam-shaper {
      fill: #7d8f9b;
    }
    .cam-stroker {
      fill: none;
      stroke: #7d8f9b;
      stroke-width: 6px;
    }

    .avatar-stroker {
      fill: #7d8f9b87;
      stroke: none;
    }

    .avatar {
      margin: auto;
    }
  }
  .foot-section {
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .cancel-section {
    position: absolute;
    background: #ffffffde;
    display: flex;
    justify-content: center;
    font-size: 30px;
    transition: 300ms;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
`;

export const WebcamCapture = (props) => {
  const [taking, setTaking] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [imgToCrop, setImgToCrop] = useState(null);
  const [processName, setProcessName] = useState("");
  const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext);

  const webcamRef = React.useRef(null);
  const inputFile = React.useRef(null)

  const onUploadImageClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleChangeFile = (e) => {
    const fileData = e.target.files[0];
    setTaking(false);
    if (fileData.size > 100000) {
      Notify("File is too large!, use a file smaller than 100kb", {
        status: "error",
      });
      return;
    }
    setShowCropper(true);
    setImgToCrop(fileData);
  }

  const capture = React.useCallback(() => {
    if (!webcamRef.current) {
      Notify("Unable to start the camera", { status: "error" });
      return;
    }
    //eslint-disable-next-line
    const src = webcamRef.current.getScreenshot();
    updateFieldValue(props.keyField, src);
    setTaking(false);
    setShowCropper(false);
    setProcessName('Camera')
  }, [webcamRef]);

  const start = React.useCallback(() => {
    // const environment = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
    // if (environment === 'development') {
    //   Notify("Unable to start the camera", { status: "error" });
    //   return;
    // }
    setTaking(true);
  }, []);

  const clearImg = () => {
    updateFieldValue(props.keyField, null);
    inputFile.current.value = null;
  };

  return (
    <Box maxWidth="340px" margin="-13px 0 0">
      <label style={{ fontWeight: "bold", textTransform: "uppercase" }}>
        {props.props.label}{" "}
        {props.props?.required && <span style={{ color: "red" }}>*</span>}
      </label>
      <input accept="image/*" onChange={handleChangeFile} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
      <Spacer space="8px" />
      <Box>
        <ImageCropper
          banner={imgToCrop}
          setShowModal={setShowCropper}
          showModal={showCropper}
          setBanner={setImgToCrop}
          aspectRatio={1 / 1}
          imageName={"imageName"}
          setBannerImg={(val) => {
            updateFieldValue(props.keyField, val);
          }}
          bannerImg={fieldValue?.[props.keyField]}
        />
      </Box>
      <CamBox>
        {taking && (
          <div className="capture-region">
            <Webcam
              audio={false}
              height={240}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              disabled={disabledFields.includes(props.keyField)}
            />
          </div>
        )}
        {!taking ? (
          <Box>
            <div style={{ position: 'relative' }}>
              {fieldValue?.[props.keyField] ? (
                <div className="preview-region">
                    {
                      !disabledFields.includes(props.keyField) && (
                        <Box className="cancel-section" width="100%" height="100%">
                          <Button
                            onClick={clearImg}
                            height={65}
                            width={65}
                            font="25px"
                            color="#7d8f9bd4"
                            round
                            icon={<i className="flexibull-cancel" ></i>}
                            style={{ margin: "auto" }}
                          />
                        </Box>

                      )
                    }
                  <img height="auto" src={fieldValue?.[props.keyField]} />
                </div>
              ) : (
                <div className="capture-region">
                  <svg viewBox="0 0 320 240">
                    <g className="avatar"  transform="translate(90 50)">
                      <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M41.23,76.332A35.266,35.266,0,0,0,76.46,41.1V35.23A35.23,35.23,0,1,0,6,35.23V41.1A35.266,35.266,0,0,0,41.23,76.332Z"
                        transform="translate(23.359)"
                        className="avatar-stroker"
                      />
                      <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M108.581,20.742a163.929,163.929,0,0,0-85.985,0A29.5,29.5,0,0,0,1,49.067v18.76H130.178V49.067A29.5,29.5,0,0,0,108.581,20.742Z"
                        transform="translate(-1 73.094)"
                        className="avatar-stroker"
                      />
                    </g>
                    <g
                      transform="matrix(0.423141,0,0,0.487874,7.26365,8.87533)"
                      className="cam-stroker"
                    >
                      <path d="M62.255,5.711L6.806,5.711L6.806,59.549" />
                    </g>
                    <g
                      transform="matrix(-0.423141,-5.18198e-17,5.97474e-17,-0.487874,312.1,232.134)"
                      className="cam-stroker"
                    >
                      <path d="M62.255,5.711L6.806,5.711L6.806,59.549" />
                    </g>
                  </svg>
                </div>
              )}
            </div>
            <div className="foot-section">
              <Button
                onClick={start}
                color="#7D8F9B"
                spaceRight="20px"
                disabled={disabledFields.includes(props.keyField)}
                >
                {fieldValue?.[props.keyField] && process === "Camera"
                  ? "Retake"
                  : "Start Camera"}
              </Button>
              <Text> Or</Text>
              <Button
                onClick={onUploadImageClick}
                color="#7D8F9B"
                fontColor="#7D8F9B"
                spaceLeft="20px"
                pale
                disabled={disabledFields.includes(props.keyField)}
              >
                {fieldValue?.[props.keyField] && processName === "Upload" ? "Re-upload passport" : "Upload passport"}
              </Button>
            </div>
          </Box>
        ) : (
          <div className="foot-section">
            <Button
              onClick={capture}
              iconLeft={<i className="form-dot-circled" />}
            >
              Capture photo
            </Button>
          </div>
        )}
      </CamBox>

      <InputWrapper {...props}>
        <InputError>{errorFields[props.keyField]}</InputError>
      </InputWrapper>
    </Box>
  );
};
