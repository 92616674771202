import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Select } from "@flexisaf/flexibull2";
import PropTypes from 'prop-types';
import NaijaStates from '../../utils/naija-states-lga.js';

import { SchemaContext } from '../container'


const StateLGA = props => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)

  const updateValue = (key, val) => {
    if (disabledFields.includes(props.keyField)) return;
    const newVal = [...(fieldValue?.[props.keyField] || [])];
    newVal[key] = val;
    if (key === 0) newVal[1] = null;

    updateFieldValue(props.keyField, newVal)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Box pad="-22px 0px 0px 0px">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Select
            style={{ width: '49%' }}
            placeholder="Select State"
            options={NaijaStates.states().map(s => ({ label: s, value: s }))}
            value={!fieldValue?.[props.keyField]?.[0] ? null : { label: fieldValue?.[props.keyField]?.[0], value: fieldValue?.[props.keyField]?.[0] }}
            onChange={({ value }) => updateValue(0, value)}
            label="State"
            required={props.props?.required}
            error={!fieldValue?.[props.keyField]?.[0] && errorFields[props.keyField]}
            disable={disabledFields.includes(props.keyField)}
          />
          <Select
            style={{ width: '49%' }}
            placeholder="Select LGA"
            options={
              !fieldValue?.[props.keyField]?.[0]
                ? []
                : NaijaStates.lgas(fieldValue?.[props.keyField]?.[0] || '')?.lgas?.sort()?.map(s => ({ label: s, value: s }))}
            value={!fieldValue?.[props.keyField]?.[1] ? null : { label: fieldValue?.[props.keyField]?.[1], value: fieldValue?.[props.keyField]?.[1] }}
            onChange={({ value }) => updateValue(1, value)}
            label="LGA"
            required={props.props?.required}
            error={!fieldValue?.[props.keyField]?.[1] && errorFields[props.keyField]}
            disable={disabledFields.includes(props.keyField)}
          />
        </div>
      </Box>
    </div>
  )
}

StateLGA.defaultProps = {
  label: null,
  placeholder: 'Select option',
  helper: null,
  type: "text",
  required: false,
  rules: {
    validation: null,
    before: null
  }
};

StateLGA.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.any,
  options: PropTypes.any.isRequired
};

export default StateLGA;