import React, { useContext } from "react";
import { TextArea as FlexiArea, Box } from "@flexisaf/flexibull2";

import { SchemaContext } from "../container";

export const TextArea = (props) => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } =
    useContext(SchemaContext);
  const Re = (val) => {
    if (disabledFields.includes(props.keyField)) return;
    const value = val.target.value;
    updateFieldValue(props.keyField, value);
  };
  return (
    <Box margin="23px 0 0 0">
      <FlexiArea
        onChange={(val) => Re(val)}
        // spaceTop="10px"
        rows={1}
        height="50px"
        block
        {...props.props}
        error={errorFields[props.keyField] || null}
        value={fieldValue?.[props.keyField]}
        disabled={disabledFields.includes(props.keyField)}
      />
    </Box>
  );
};
