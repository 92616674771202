import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Layout as Panel, Button } from "@flexisaf/flexibull2";

import { Layout } from './layout';
import { Theme } from '../theme.js';
import { validateSection } from '../validators';

import './fonts/fonts.css';


export const ContainerWrapper = styled('div').attrs({ className: 'flex-render-container' })`
    margin:0;
    padding:20px;
    max-width: 900px;
    ${props => props.styler === "wizard" && css`max-width: 100%;`}
    margin: 0 auto;

    @media(max-width: 800px) {
      padding: 0;
    }
`;

export const SchemaContext = React.createContext();

export const Container = props => {
  const { name, description, disableLabels, layout, submit, theme: t = {}, styler } = props;

  const [schema, setSchema] = useState({ name, description, layout })
  const [errorFields, setErrorFields] = useState({})
  const [viewedGuides, setViewedGuides] = useState([])

  const getLayout = () => !Array.isArray(layout) ? [layout] : layout;;

  function filterSections(sections) {
    if (props.formFiller) {
      if (props?.formFiller?.toLowerCase() === 'applicant') {
        const filteredSections = sections.filter((section) => section?.refereeSectionType?.toLowerCase() !== 'referee');
        return filteredSections;
      } else if (props?.formFiller?.toLowerCase() === 'referee') {
        const filteredSections = sections.filter((section) => section?.refereeSectionType?.toLowerCase() === 'referee');
        return filteredSections;
      }
    } else {
      return sections;
    }
  }

  const handleSubmit = () => {
    const tempSchema = Object.assign({}, schema);
    const filteredTempSchema = filterSections(tempSchema.layout.sections);
    const lastSection = filteredTempSchema[filteredTempSchema.length - 1]
    const validationErrors = validateSection(
      lastSection || [],
      props.fieldValue,
      props.duplicateFields);

    setErrorFields(validationErrors)
    if (Object.keys(validationErrors)?.length === 0) {
      submit(props.fieldValue, schema)
    }
  }

  let newTheme = { ...Theme, ...t };
  return (
    <SchemaContext.Provider value={{
      schema,
      setSchema,
      styler,
      disabledFields: props.disabledFields,
      fieldValue: props.fieldValue,
      updateFieldValue: props.setFieldValue,
      duplicateFields: props.duplicateFields,
      setDuplicateFields: props.setDuplicateFields,
      errorFields,
      setErrorFields,
      viewedGuides,
      setViewedGuides,
    }}>
      <Panel theme={newTheme}>
        <ContainerWrapper styler={styler}>
          {styler !== "wizard" && name && <h3>{name}</h3>}
          {getLayout() && getLayout().map((lay, index) => (
            <Layout
              {...lay}
              name={name}
              key={index}
              checkIsValid={() => isValid(schema, setSchema)}
              disableLabels={disableLabels}
              description={description}
              saving={props.saving}
              onSubmit={handleSubmit}
              formFiller={props.formFiller}
              submitTitle={props.submitTitle}
              submit={<Button progress={props.saving} onClick={handleSubmit}>{props.submitTitle || 'Submit'}</Button>} />
          ))}
        </ContainerWrapper>
      </Panel>
    </SchemaContext.Provider>

  )
}
