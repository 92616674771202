import React, { useContext } from "react";
import { RadioButton } from "@flexisaf/flexibull2";
import { SchemaContext } from "../container";
import { InputError, InputWrapper } from "../input-wrapper";

export const Radio = (props) => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(
    SchemaContext
  );

  if (!props.props.radio) {
    return null;
  }
  const handleChange = (e) => {
    if (disabledFields.includes(props.keyField)) return;
    const { value } = e.target;
    updateFieldValue(props.keyField, value);
  };
  return (
    <div style={{ display: "flex", marginTop: "-22px" }}>
      <label
        style={{
          fontSize: "0.9em",
          fontWeight: "700",
          marginBottom: "10px",
          textTransform: "uppercase",
        }}
      >
        {props.props.label}{" "}
        {props.props?.required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div>
        {props.props.radio &&
          props.props.radio.map((radio, index) => {
            return (
              <RadioButton
                onChange={handleChange}
                {...radio}
                checked={fieldValue?.[props.keyField] === radio.value}
                key={index}
                value={radio.value}
                name={`radio-${props.keyField}`}
                disabled={disabledFields.includes(props.keyField)}
              />
            );
          })}
      </div>

      <InputWrapper {...props}>
        <InputError>
          {errorFields[props.keyField]}
        </InputError>
      </InputWrapper>
    </div>
  );
};
