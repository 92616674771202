
export const Theme = {
  PrimaryGrey: '#62707B',
  PrimaryDark: '#3D2B56',

  PrimaryBackground: '#F8F8F5',
  PrimaryGreen: '#59d973',
  PrimaryMint: '#95fd35',
  PrimaryRed: '#ea2a8a',
  PrimaryYellow: '#F2C02E',
  PrimaryOrange: '#EF7E23',
  PrimaryBlue: '#5BB1F2',
  SecondaryColor: '#be7efc',
  TertiaryColor: '#ecdbfe',

  PrimaryFont: 'Inter',


  PrimaryBorderColor: '#D8D8E3',
  PrimaryFade: '#F5F5F7',
  PrimaryInputOutline: '#9D9DB7',
  PrimaryCellPad: '15px',

  PrimaryBorderRadius: '5px',
  PrimaryFontSize: '12px' 
};