import React from 'react';
import styled, { css } from "styled-components";
import { transparentize, darken } from "polished";
import { Theme } from '../theme';


export const InputWrapper = styled.div`
  position: relative;
  & input {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 0 10px;
    height: 36px;
    border: 1px solid ${Theme.PrimaryInputOutline};
    border-radius: ${Theme.PrimaryBorderRadius};
    &:focus {
      outline: none;
      border: 1px solid ${Theme.PrimaryBlue};
      box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryBlue)};
    }
    &::placeholder {
      color: ${darken(0, Theme.PrimaryInputOutline)};
    }
    &:disabled {
      background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
      border: 1px dashed ${darken(0.1, Theme.PrimaryInputOutline)};
    }
    ${(props) =>
    props.error &&
    css`
        border: 1px solid ${Theme.PrimaryRed};
        box-shadow: 0 0 2px ${transparentize(0.6, Theme.PrimaryRed)};
      `}
  }

  & .type {
    display: inline-flex;
    position: absolute;
    height: 36px;
    z-index: 1;
    width: 36px;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    color: ${darken(0.1, Theme.PrimaryInputOutline)};
  }
`;

export const InputLabel = styled.div`
  margin-bottom: 6px;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
`;

export const InputError = styled.div`
    font-size: .7rem;
    font-style: italic;
    text-align: left;
    position: absolute;
    width: 100%;
    bottom: -19px;
    right: 0;
    color: ${Theme.PrimaryRed};
`;