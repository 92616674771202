import React, { useContext } from "react";
import { SingleDates, Box } from "@flexisaf/flexibull2";
import styled from "styled-components";

import { SchemaContext } from "../container";
import { InputError, InputWrapper } from "../input-wrapper";

const SingeDateWrapper = styled(Box)`
  @media (max-width: 600px) {
    max-width: calc(100vw - 100px);
  }
`
const SingeDate = (props) => {
  const { disabledFields, fieldValue, errorFields, updateFieldValue } = useContext(SchemaContext);

  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  const Re = (val, v) => {
    if (disabledFields.includes(props.keyField)) return;
    const value = val;
    if (isValidDate(value)) updateFieldValue(props.keyField, value);
  };

  return (
    <SingeDateWrapper margin="-22px 0px 0px 0px">
    <InputWrapper {...props}>
      <Box pad="0 0 10px">
        {props.props.label && (
          <label style={{ fontWeight: "700", fontSize: "0.9em", textTransform: "uppercase"}} >
            {props.props.label} {props.props?.required && <span style={{ color: 'red' }}>*</span>}
          </label>
        )}
      </Box>
      <SingleDates
        name={props.keyField}
        dayPlaceholder="day"
        monthPlaceholder="month"
        yearPlaceholder="year"
        // DayLabel="Day"
        // MonthLabel="Month"
        // YearLabel="Year"
        dayGap="10px"
        yearGap="10px"
        disabled={disabledFields.includes(props.keyField)}
        value={fieldValue?.[props.keyField] ? new Date(fieldValue?.[props.keyField]) : null}
        onDateChange={Re}
      />
      <InputError>
        {errorFields[props.keyField]}
      </InputError>
      </InputWrapper>
    </SingeDateWrapper>
  );
};
export default SingeDate;