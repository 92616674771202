import React, { useContext } from 'react';
import Autocomplete from "react-google-autocomplete";
import styled, { css } from "styled-components";
import { transparentize, darken } from "polished";

import PropTypes from 'prop-types';
import { SchemaContext } from '../container'
import { GoogleAPIKey } from '../../constants'
import { Theme } from '../../theme';
import { Box } from '@flexisaf/flexibull2/build/layout';

const InputWrapper = styled.div`
  margin-top: -10px;
  position: relative;
  & input {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 0 10px;
    height: 36px;
    border: 1px solid ${Theme.PrimaryInputOutline};
    border-radius: ${Theme.PrimaryBorderRadius};
    &:focus {
      outline: none;
      border: 1px solid ${Theme.PrimaryBlue};
      box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryBlue)};
    }
    &::placeholder {
      color: ${darken(0, Theme.PrimaryInputOutline)};
    }
    &:disabled {
      background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
      border: 1px dashed ${darken(0.1, Theme.PrimaryInputOutline)};
    }
    ${(props) =>
    props.error &&
    css`
        border: 1px solid ${Theme.PrimaryRed};
        box-shadow: 0 0 2px ${transparentize(0.6, Theme.PrimaryRed)};
      `}
  }

  & .type {
    display: inline-flex;
    position: absolute;
    height: 36px;
    z-index: 1;
    width: 36px;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    color: ${darken(0.1, Theme.PrimaryInputOutline)};
  }
`;

const InputLabel = styled.div`
  margin-bottom: 8px;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
`;

const InputError = styled.div`
    font-size: .7rem;
    font-weight: lighter;
    font-style: italic;
    text-align: right;
    position: absolute;
    width: 100%;
    bottom: -19px;
    right: 0;
    color: ${transparentize(0.2, Theme.PrimaryRed)};
`;

const Location = props => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)

  const handleSelectPlace = place => {
    if (disabledFields.includes(props.keyField)) return;
    updateFieldValue(props.keyField, place.formatted_address);
  }
  return (
    <Box margin="-22px 0px 0px 0px">
    <InputWrapper {...props}>
      {props.props?.label && (
        <InputLabel>
          {props.props?.label} {props.props?.required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
      )}
      <Autocomplete
        apiKey={GoogleAPIKey}
        onPlaceSelected={handleSelectPlace}
        placeholder={props?.props?.placeholder || "Enter a Location"}
        style={{ width: "100%", height: "42px", border: "1px solid #D8D8E3", borderRadius: "3px" }}
        defaultValue={fieldValue?.[props.keyField]}
        options={{
          componentRestrictions: { country: "ng" },
        }}
        disabled={disabledFields.includes(props.keyField)}
      />
      <InputError>
        {errorFields[props.keyField]}
      </InputError>
    </InputWrapper>
    </Box>
  )
}

Location.defaultProps = {
  label: null,
  placeholder: 'enter value',
  helper: null,
  type: "text",
  rules: {
    validation: null,
    required: false,
    before: null
  }
};

Location.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.any,
};

export default Location;