import { getFieldKey } from "./utils/helpers";

export const validateRequired = (colValue, columnItem) => {
	if (columnItem?.props?.required) {
		if (!colValue || (columnItem?.props?.source === "nigeria-states-lga" && !(colValue?.[0] && colValue?.[1]))) {
			return [false, 'This field is required'];
		}
	}
	return [true, ''];
}

export const validateMinimumLength = (colValue, columnItem) => {
	if (columnItem?.rules?.minLength) {
		if (colValue?.length < columnItem?.rules?.minLength) {
			return [false, `This field length need to be larger than or equal to ${columnItem?.rules?.minLength}`];
		}
	}
	return [true, ''];
}

export const validateMaximumLength = (colValue, columnItem) => {
	if (columnItem?.rules?.maxLength) {
		if (colValue?.length > columnItem?.rules?.maxLength) {
			return [false, `This field need to be less than or equal to ${columnItem?.rules?.maxLength}`];
		}
	}
	return [true, ''];
}

export const validateMinimumNumber = (colValue, columnItem) => {
	if (columnItem?.rules?.minNumber) {
		if (colValue < columnItem?.rules?.minNumber) {
			return [false, `This field need to be larger than or equal to ${columnItem?.rules?.minNumber}`];
		}
	}
	return [true, ''];
}

export const validateMaximumNumber = (colValue, columnItem) => {
	if (columnItem?.rules?.maxNumber) {
		if (colValue > columnItem?.rules?.minNumber) {
			return [false, `This field need to be less than or equal to ${columnItem?.rules?.minNumber}`];
		}
	}
	return [true, ''];
}

export const validateEmail = (colValue, columnItem) => {

	if (columnItem.type === 'email' && colValue) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const check = re.test(String(colValue).toLowerCase());
		if (!check) {
			return [false, `${colValue} is not a valid email address`];
		}
	}
	return [true, ''];
}

export const validateSection = (section, fieldValue, duplicateFields) => {

	const rrs = !Array.isArray(section?.rows) ? [section?.rows] : section?.rows;
	const duplicateIds = duplicateFields?.sections?.[section.id] || [];
	const newRows = [
		{ id: section.id, rows: [...rrs.map(r => ({ ...r, duplicateSectionNumber: section.id, isMain: true }))] },
		...duplicateIds.map((id) => ({ id, rows: [...rrs.map(r => ({ ...r, duplicateSectionNumber: id }))] })),
	].reduce((acc, cur) => ([...acc, ...cur.rows]), []);

	let err = {};
	newRows.forEach((rw) => {

		const cls = !Array.isArray(rw.cols) ? [rw.cols] : rw.cols;
		const duplicateRowIds = duplicateFields?.rows?.[`${rw.duplicateSectionNumber}-${rw.id}`] || [];
		const newCols = [
			{
				id: rw.id, cols: [...cls.map(c => ({
					...c, keyField: getFieldKey({
						duplicateSectionNumber: rw.duplicateSectionNumber,
						duplicateRowNumber: rw.id,
						fieldId: c.fieldId
					})
				}))]
			},
			...duplicateRowIds.map((id) => ({
				id,
				cols: [...cls.map(c => ({
					...c, keyField: getFieldKey({
						duplicateSectionNumber: rw.duplicateSectionNumber,
						duplicateRowNumber: id,
						fieldId: c.fieldId
					})
				}))]
			})),
		].reduce((acc, cur) => ([...acc, ...cur.cols]), []);;

		newCols.forEach((co) => {
			const colValue = fieldValue[co.keyField];

			const validRequired = validateRequired(colValue, co);
			if (!validRequired[0]) {
				err[co.keyField] = validRequired[1]
				return err;
			}

			const validMinimumNumber = validateMinimumNumber(colValue, co);
			if (!validMinimumNumber[0]) {
				err[co.keyField] = validMinimumNumber[1]
				return err;
			}

			const validMaximumNumber = validateMaximumNumber(colValue, co);
			if (!validMaximumNumber[0]) {
				err[co.keyField] = validMaximumNumber[1]
				return err;
			}

			const validEmail = validateEmail(colValue, co);
			if (!validEmail[0]) {
				err[co.keyField] = validEmail[1]
				return err;
			}

			const validMinimumLength = validateMinimumLength(colValue, co);
			if (!validMinimumLength[0]) {
				err[co.keyField] = validMinimumLength[1]
				return err;
			}

			const validMaximumLength = validateMaximumLength(colValue, co);
			if (!validMaximumLength[0]) {
				err[co.keyField] = validMaximumLength[1]
				return err;
			}

		})
	})
	return err
}