import React, { useContext } from 'react';
import { Switch as Switcher } from "@flexisaf/flexibull2";
import PropTypes from 'prop-types';
import { SchemaContext } from '../container'

export const Switch = props => {
	const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)
	
	const Re = val => {
		if (disabledFields.includes(props.keyField)) return;
		const value = val.target.value;
		updateFieldValue(props.keyField, value)
	}

	return <Switcher
		onChange={Re}
		{...props.props}
		error={errorFields[props.keyField] || null}
		block
		spaceTop={props.props.label ? "10px" : 0}
		value={fieldValue?.[props.keyField]}
		disabled={disabledFields.includes(props.keyField)}
	/>
}

Switch.defaultProps = {
	label: null,
	placeholder: 'Select option',
	helper: null,
	type: "text",
	required: false,
	rules: {
		validation: null,
		before: null
	}
};

Switch.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	helper: PropTypes.string,
	type: PropTypes.string,
	rules: PropTypes.any,
	options: PropTypes.any.isRequired
};
