import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Wizard } from "@flexisaf/flexibull2";
import { Section } from "./section";
import { SchemaContext } from "./container";
import { validateSection } from '../validators';

export const LayoutWrapper = styled("div").attrs({
  className: "flex-render-layout",
})`
  margin: 0;
  padding: 0;

  & p{
    font-family: "Inter";
    font-size: 14px;
    margin-left: 35px;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  & .flexi-wizard {
    box-shadow: none;
    margin: -1.1rem auto;
    background: transparent;

    @media (max-width: 500px) {
      & .flexi-wizard-wrapper {
        grid-template-columns: 1fr;
      }
  
      & .flexi-wizard-left {
        display: none;
      }
    }

    @media (max-width: 800px) {
      & .flexi-wizard-right {
        margin-top: 0;
      }
    }

  }
`;

export const Layout = (props) => {
  let { name, description, sections, disableLabels = true } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [previous, setPrevious] = useState({});
  const { fieldValue, setErrorFields, duplicateFields } = useContext(SchemaContext);
  if (sections) {
    sections = !Array.isArray(sections) ? [sections] : sections;
  }

  function handleNext(section, index) {
    const skips = Object.keys(section.skip || {}).sort().reverse();
    const validationErrors = validateSection(section || [], fieldValue, duplicateFields);
    setErrorFields(validationErrors)
    if (Object.keys(validationErrors)?.length === 0) {
      const selectedSkip = skips.map((_, ind) => section.skip?.[skips[ind]]?.[fieldValue[skips[ind]]]).filter(a => a);
      const skipPageNumber = selectedSkip?.[0] ? selectedSkip[0] - 1: null;
      const nextPageNum = skipPageNumber || section.next - 1 || (index + 1);
      setCurrentStep(nextPageNum);
      setPrevious(c => ({ ...c, [nextPageNum]: index }))
    }
  }

  function handlePrevious(i) {
    if (currentStep > 0) {
      setCurrentStep(previous[i]);
    }
  }

  function filterSections(sections) {
    if (props.formFiller) {
      if (props?.formFiller?.toLowerCase() === 'applicant') {
        const filteredSections = sections.filter((section) => section?.refereeSectionType?.toLowerCase() !== 'referee');
        return filteredSections;
      } else if (props?.formFiller?.toLowerCase() === 'referee') {
        const filteredSections = sections.filter((section) => section?.refereeSectionType?.toLowerCase() === 'referee');
        return filteredSections;
      }
    } else {
      return sections;
    }
  }

  return (
    <LayoutWrapper>
      {description && <p dangerouslySetInnerHTML={{ __html: description }} />}
      <Wizard view={currentStep} title={name} autoProceed={false} minimizedLabels={true} disableLabels={!!disableLabels}>
        {filterSections(sections)?.length > 1 ? (
          filterSections(sections)?.map((section, i) => (
            <div title={<div>{section.title}</div>} key={i}>
              <Section
                {...section}
                onPrevious={i !== 0 ? () => handlePrevious(i) : undefined}
                onNext={i !== (filterSections(sections).length - 1) ? () => handleNext(section, i) : undefined}
                onSubmit={i === (filterSections(sections).length - 1) ? props.onSubmit : undefined}
                saving={props.saving}
                submitTitle={props.submitTitle}
              />
            </div>
          ))
        ) : (
          <div title={filterSections(sections)[0].title}>
            <Section
              {...filterSections(sections)[0]}
              onSubmit={props.onSubmit}
              submitTitle={props.submitTitle}
            />
          </div>
        )}
      </Wizard>
    </LayoutWrapper>
  );
};
