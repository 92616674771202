import React, { useContext, useMemo } from 'react';
import { Box, Select } from "@flexisaf/flexibull2";
import PropTypes from 'prop-types';
import { getCountries, getStates } from 'country-state-picker';

import { SchemaContext } from '../container'
import subjects from '../../utils/subjects';


const Lookup = props => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)
  const handleChange = val => {
    if (disabledFields.includes(props.keyField)) return;
    const value = val.value;
    updateFieldValue(props.keyField, value)
  }

  const getOptions = () => {
    switch (props.props?.source) {
      case 'nigeria':
        return getStates('ng')?.map((s) => ({ label: s, value: s })) || []
      case 'countries':
        return getCountries().map((c) => ({ label: c.name, value: c.code })) || []
      case 'subjects':
        return subjects.map((s) => ({ label: s, value: s })) || []
      default:
        return [];
    }
  }
  const keyedOptions = useMemo(() => {
    const options = {};
    getOptions()?.forEach(opt => {
      options[opt.value] = opt;
    });
    return options;
  }, [props.props.options]);

  return (
    <Box margin="-10px 0px 0px 0px">
      <Select
        onChange={val => handleChange(val)}
        {...props.props}
        error={errorFields[props.keyField] || null}
        block
        name={props.keyField}
        spaceTop={props.props.label ? "10px" : 0}
        options={getOptions()}
        value={keyedOptions[fieldValue?.[props.keyField]]}
        disable={disabledFields.includes(props.keyField)}
      />
    </Box>
  )
}

Lookup.defaultProps = {
  label: null,
  placeholder: 'Select option',
  helper: null,
  type: "text",
  required: false,
  rules: {
    validation: null,
    before: null
  }
};

Lookup.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.any
};

export default Lookup;