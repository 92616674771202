import React, { useContext } from 'react';
import { Checkbox as Check } from "@flexisaf/flexibull2";
import PropTypes from 'prop-types';
import {SchemaContext} from '../container'



export const Checkbox = props => {
    const { errorFields, disabledFields, fieldValue, updateFieldValue } = useContext(SchemaContext)
    const handleChange = () => {
        if (disabledFields.includes(props.keyField)) return;
        updateFieldValue(props.keyField, !fieldValue?.[props.keyField]);
    }

    return <Check
        onChange={handleChange}
        {...props.props}
        error={errorFields[props.keyField] || null}
        name={props.keyField}
        checked={fieldValue?.[props.keyField]}
        block
        spaceTop={props.props.label ? "10px": 0}
        value={fieldValue?.[props.keyField]}
        disabled={disabledFields.includes(props.keyField)}
        />
}

Checkbox.defaultProps = {
    label: null,
    placeholder: 'Select option',
    helper: null,
    type: "text",
    required: false,
    rules: {
        validation: null,
        before: null
    }
  };
  
  Checkbox.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helper: PropTypes.string,
    type: PropTypes.string,
    rules: PropTypes.any,
    options: PropTypes.any.isRequired
};