import React, { useContext } from 'react';
import { Input , Box , Text} from "@flexisaf/flexibull2";
import PropTypes from 'prop-types';
import { SchemaContext } from '../container'
import styled from 'styled-components';
const Textstyle = styled(Text)`    
    text-transform: uppercase;
    position: relative;
    font-weight: 700;
    display: block;
    font-size: 0.9em;

`
const Gridstyle = styled(Box)
`
display:flex;
flex-direction:column
`
const InputComponent = (props) => {
    const { errorFields, disabledFields, fieldValue, updateFieldValue } = useContext(SchemaContext);

    return (
        <Gridstyle >
        <Textstyle style={{ marginBottom:`${props.props.label ? "10px" : "0"}`}}>{props.props.label}
          <span style={{color:"#ea2a8a"}}>{props.props.required ? "*" : ""}</span></Textstyle>
    <Input
        type={props.type}
        {...props.props}
        error={errorFields[props.keyField] || null}
        value={fieldValue?.[props.keyField] || ''}
        onChange={({ target: { value } }) => {
            if (disabledFields.includes(props.keyField)) return;
            updateFieldValue(props.keyField, value);
        }}
        block
        label={null}
        disabled={disabledFields.includes(props.keyField)}
        space-top={props.props.label ? "10px" : 0}
    />
    </Gridstyle>
    )
}

InputComponent.defaultProps = {
    type: "text"
};

InputComponent.propTypes = {
    type: PropTypes.string,
    keyField: PropTypes.string,
};

export default InputComponent;
