import React, { useState } from 'react';
import {
  Box,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from '@flexisaf/flexibull2';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import './style.css';

const ImageCropper = (props) => {
  const {
    banner,
    setShowModal,
    showModal,
    setBanner,
    imageName,
    setBannerImg,
    bannerImg,
    aspectRatio = NaN,
  } = props;

  const [cropper, setCropper] = useState(null);
  const [base64, setBase64] = useState(null);

  const onFileChange = async (banner) => {
    if (banner !== undefined) {
      const file = banner;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          setBase64(reader.result);
        },

        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCropper = (banner) => {
    onFileChange(banner);
  };

  if (banner !== undefined) {
    handleCropper(banner);
  }

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  function base64Mime(encoded) {
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
  }

  const handleUpload = (e) => {
    setShowModal(false);
    const croppedData = cropper.getCroppedCanvas().toDataURL();
    setBannerImg(croppedData);
    const mimeType = base64Mime(croppedData);
    urltoFile(croppedData, imageName, mimeType).then((file) => {
      setBanner(file);
    });
  };
  const handleCancel = (e) => {
    if (bannerImg !== undefined) {
      setBannerImg(bannerImg);
    }
    setShowModal(false);
    setBanner('');
  };

  return (
    <div>
      <div>
        {base64 ? (
          <Modal
            outerclick
            open={showModal}
            onClose={() => setShowModal(false)}
            center={false}
          >
            <ModalBody width="40vw">
              <Box pad="20px">
                <Cropper
                  style={{ height: 400, width: '100%' }}
                  src={base64}
                  viewMode={1}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive
                  autoCropArea={1}
                  aspectRatio={aspectRatio}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
              </Box>
              <ModalFooter>
                <Box pad="20px" align="right">
                  <Button
                    spaceRight
                    color="transparent"
                    fontColor="rgba(0,0,0,0.8)"
                    iconLeft={<i className="flexibull-angle-left" />}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleUpload}
                    iconRight={<i className="flexibull-angle-right" />}
                  >
                    Upload
                  </Button>
                </Box>
              </ModalFooter>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default ImageCropper;
