import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Overlay from 'react-overlays/Overlay';
import { Button } from "@flexisaf/flexibull2/build/button";
import { SchemaContext } from "./container";

const Tooltip = styled("div")`
  position: absolute;
  opacity: 0.9 !important;
  top: 20px !important;
  right: 10px;
  z-index: 2;
`;

const TooltipBody = styled("div")`
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  max-width: 200px;
  background-color: #825efb;
`;


export const TooltipWrapper = ({ children, ...props }) => {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const { viewedGuides = [], setViewedGuides } = useContext(SchemaContext);

  return (
    <div ref={containerRef} style={{ position: 'relative', opacity: 1 }}>
      <div ref={triggerRef} />
      {React.cloneElement(children, { yes: 'yes' })}
      {
        props?.props?.guide?.trim() && !viewedGuides.includes(props.keyField) && (
          <Overlay
            show
            rootClose
            offset={[0, 10]}
            placement='auto'
            container={containerRef}
            target={triggerRef}
          >
            {({ props: pp, placement }) => (
              <Tooltip {...pp} show placement={placement}>
                <TooltipBody>
                  <div>{props?.props?.guide?.trim()}</div>
                  <Button onClick={() => setViewedGuides(v => ([...v, props.keyField]))}>X</Button>
                </TooltipBody>
              </Tooltip>
            )}
          </Overlay>
        )
      }
    </div>
  )
};