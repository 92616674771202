import React, { Fragment, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Row } from "./row";
import { Box, Button } from "@flexisaf/flexibull2";
import { v4 as uuidv4 } from "uuid";

import { SchemaContext } from "./container";

export const SectionWrapper = styled("div").attrs({
  className: "flex-render-section",
})`
  margin: 5.5rem 0 30px;
  padding: 0;

  ${(props) =>
    props.styler === "sectioned" &&
    css`
      box-shadow: 0 5px 25px rgba(0, 0, 0, 0.08);
      border-radius: ${(props) => props.theme.PrimaryBorderRadius};

      & .flex-render-section-header {
        background: #fff;
        padding: 20px 20px;
        border-radius: ${(props) => props.theme.PrimaryBorderRadius}
          ${(props) => props.theme.PrimaryBorderRadius} 0 0;
        border-bottom: 1px solid #ccc;
        & h3 {
          margin: 0 0 5px 0;
          padding: 0;
          font-size: 1.4rem;
        }
        & p {
          margin: 0;
          padding: 0;
          font-size: 0.8rem;
        }
      }
      & .flex-render-section-body {
        padding: 40px;
        border-radius: 0 0 ${(props) => props.theme.PrimaryBorderRadius}
          ${(props) => props.theme.PrimaryBorderRadius};
      }
    `}

  ${(props) =>
    props.styler === "wizard" &&
    css`
      & .flex-render-section-header {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
        & h3 {
          margin: 0 0 5px 0;
          padding: 0;
          font-size: 1.6rem;
          font-weight: 800;
          text-transform: capitalize;
        }
        & p {
          margin: 0;
          padding: 0;
          font-size: 0.8rem;
        }
      }
      & .flex-render-section-body {
        padding: 40px 0;
      }

      @media (max-width: 800px) {
        .flex-render-section-body {
          padding-top: 5px;
        }
      }
    `}
`;

const SectionNavigationBox = styled(Box)`
  @media (max-width: 600px) {
    & .flexi-button{
      font-size: 14px;
      width: 90px !important;
    }
  }
`
export const Section = (props) => {
  const { styler, duplicateFields, setDuplicateFields } =
    useContext(SchemaContext);
  const [rowSchemas, setRowSchemas] = useState([]);
  let { title, description, rows, id: sectionId, canDuplicate, addSectionAlias, maxDuplicate } = props;
  useEffect(() => {
    const rrs = !Array.isArray(rows) ? [rows] : rows;
    const duplicateIds = duplicateFields?.sections?.[sectionId] || [];
    const newRows = [
      { id: sectionId, rows: rrs },
      ...duplicateIds.map((id) => ({ id, rows: [...rrs] })),
    ];

    setRowSchemas(newRows);
  }, [duplicateFields, rows]);

  const handleAddEntry = () => {
    const duplicateFieldsCopy = { ...duplicateFields };
    duplicateFieldsCopy.sections[sectionId] = [
      ...(duplicateFieldsCopy?.sections?.[sectionId] || []),
      uuidv4(),
    ];
    setDuplicateFields(duplicateFieldsCopy);
  };

  const deleteEntrySection = (r) => {
    const duplicateFieldsCopy = { ...duplicateFields };
    duplicateFieldsCopy.sections[sectionId] = (
      duplicateFieldsCopy?.sections?.[sectionId] || []
    ).filter((id) => id !== r.id);
    setDuplicateFields(duplicateFieldsCopy);
  };
  return (
    <SectionWrapper styler={styler}>
      <Box style={{ minHeight: "200px" }}>
      <SectionNavigationBox align="right" margin="50px 0">
        {props.onPrevious && (
          <Button
            iconLeft={<i className="flexibull-left" />}
            width={120}
            height={48}
            onClick={props.onPrevious}
            spaceRight
            pale
          >
            Previous
          </Button>
        )}
        {props.onNext && (
          <Button
            iconRight={<i className="flexibull-right" />}
            width={120}
            height={48}
            onClick={props.onNext}
          >
            Next
          </Button>
        )}
        {props.onSubmit && (
          <Button
            iconRight={<i className="flexibull-right" />}
            height={48}
            onClick={props.onSubmit}
            progress={props.saving}
          >
            {props.submitTitle || "Submit"}
          </Button>
        )}
      </SectionNavigationBox>
        <div className="flex-render-section-header">
          {title && <h3>{title}</h3>}
          {description && (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
        {rowSchemas.map((r, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <Box>
                <Box align="right">
                  <Button
                    pale
                    height={30}
                    onClick={() => deleteEntrySection(r)}
                    color="#EE1B87"
                    fontColor="#EE1B87"
                    round
                    font="16px"
                    icon={<i className="flexibull-cancel"></i>}
                  />
                </Box>
                <Box>{title && <h3>{`${title} (${index + 1})`}</h3>}</Box>
              </Box>
            )}
            <div key={index}>
              <div className="flex-render-section-body">
                {r &&
                  r.rows.map((row, j) => (
                    <Row
                      key={j}
                      duplicateNumber={r.id}
                      sectionId={sectionId}
                      {...row}
                    />
                  ))}
              </div>
              <Box pad={50} />
            </div>
          </Fragment>
        ))}
        {canDuplicate && rowSchemas.length < (+maxDuplicate > 0 ? maxDuplicate : Infinity) &&  (
          <Box align="right">
            <Button
              height={38}
              onClick={handleAddEntry}
              spaceRight
              pale
              iconRight={<i className="flexibull-plus" />}
            >
              {addSectionAlias || 'Add Entry'}
            </Button>
          </Box>
        )}
      </Box>
      <SectionNavigationBox align="right" margin="50px 0">
        {props.onPrevious && (
          <Button
            iconLeft={<i className="flexibull-left" />}
            width={120}
            height={48}
            onClick={props.onPrevious}
            spaceRight
            pale
          >
            Previous
          </Button>
        )}
        {props.onNext && (
          <Button
            iconRight={<i className="flexibull-right" />}
            width={120}
            height={48}
            onClick={props.onNext}
          >
            Next
          </Button>
        )}
        {props.onSubmit && (
          <Button
            iconRight={<i className="flexibull-right" />}
            height={48}
            onClick={props.onSubmit}
            progress={props.saving}
          >
            {props.submitTitle || "Submit"}
          </Button>
        )}
      </SectionNavigationBox>
    </SectionWrapper>
  );
};
