import React from 'react';
import { Container } from './components/container';
export * from './utils/processFormUtil';

const FormRender = props => {
    const Data = props.data;
    const submit = props.submit
    return <Container {...Data}
        styler={props.styler}
        theme={props.theme}
        submit={submit}
        submitTitle={props.submitTitle}
        fieldValue={props.fieldValue || {}}
        disabledFields={props.disabledFields || []}
        disableLabels={props.disableLabels}
        setFieldValue={props.setFieldValue || (() => null)}
        saving={props.saving}
        duplicateFields={props.duplicateFields}
        setDuplicateFields={props.setDuplicateFields}
        formFiller={props.formFiller || ''}
        />
}
export default FormRender;