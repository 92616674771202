import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { SchemaContext } from '../container'
import { Box } from '@flexisaf/flexibull2';
import { InputWrapper, InputError } from '../input-wrapper';

const WSYIWYG = (props) => {
    const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)

    const Re = val => {
        if (disabledFields.includes(props.keyField)) return;
        const value = val;
        updateFieldValue(props.keyField, value)
    }
    return (
        <Box margin="-22px 0px 0px 0px">
            <label style={{ fontWeight: "700", fontSize: "0.9em", textTransform: "uppercase" }}>
                {props?.props?.label} {props.props?.required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <Editor
                spaceTop={props.props.label ? "10px" : 0}
                rows={1}
                height='auto'
                block
                {...props.props}
                init={{
                    icons: "jam",
                    resize: false,
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'a11ychecker advcode casechange export formatpainter linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tinymcespellchecker'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist  | table | removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                apiKey="5w35pqswxkvbf4fy3qdxj8fgorjgdk8ncsw7p56klmuas01r"
                value={fieldValue?.[props.keyField]}
                onEditorChange={Re}
                disabled={disabledFields.includes(props.keyField)}
            />
            <InputWrapper {...props}>
                <InputError>
                    {errorFields[props.keyField]}
                </InputError>
            </InputWrapper>
        </Box>
    )
}
export default WSYIWYG;
