import React, { useContext, useMemo } from "react";
import { Box, Select, Text } from "@flexisaf/flexibull2";
import PropTypes from "prop-types";
import styled from "styled-components";

import { SchemaContext } from "../container";

const Textstyle = styled(Text)`
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  display: block;
  font-size: 0.9em;
`;
const Gridstyle = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Selector = (props) => {
  const { disabledFields, errorFields, fieldValue, updateFieldValue } =
    useContext(SchemaContext);

  const handleChange = (val) => {
    if (disabledFields.includes(props.keyField)) return;
    const value = val.value;
    updateFieldValue(props.keyField, value);
  };

  const keyedOptions = useMemo(() => {
    const options = {};
    props?.props?.options?.forEach((opt) => {
      options[opt.value] = opt;
    });
    return options;
  }, [props.props.options]);

  return (
    <Box>
      <Gridstyle>
        <Textstyle
          style={{ marginBottom: `${props.props.label ? "10px" : "0"}` }}
        >
          {props.props.label}
          <span style={{ color: "#ea2a8a" }}>
            {props.props.required ? "*" : ""}
          </span>
        </Textstyle>

        <Select
          onChange={(val) => handleChange(val)}
          {...props.props}
          error={errorFields[props.keyField] || null}
          block
          label={null}
          value={keyedOptions[fieldValue?.[props.keyField]]}
          disable={disabledFields.includes(props.keyField)}
        />
      </Gridstyle>
    </Box>
  );
};

Selector.defaultProps = {
  label: null,
  placeholder: "Select option",
  helper: null,
  type: "text",
  required: false,
  rules: {
    validation: null,
    before: null,
  },
};

Selector.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.any,
};
