import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {SchemaContext} from '../container'
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Box, Spacer } from '@flexisaf/flexibull2';
import { InputWrapper, InputError} from '../input-wrapper';

const Phone = props => {
    const { disabledFields, errorFields, fieldValue, updateFieldValue } = useContext(SchemaContext)

    const Re = value => {
      if (disabledFields.includes(props.keyField)) return;
      updateFieldValue(props.keyField, value)
    }

    return (
      <Box>
        <InputWrapper {...props}>
        <label style={{ fontWeight: "700", fontSize: "0.9em", textTransform: "uppercase" }}>
          {props?.props?.label} {props.props?.required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <Spacer space="8px" />
        <ReactPhoneInput
          inputExtraProps={{
            ...props.props
          }}
          containerStyle={{
            width: '100%'
          }}
          inputStyle={{
            width: '100%',
            height: '42px',
            border: "1px solid #D8D8E3",
            borderRadius: '3px',
          }}
          country={"ng"}
          enableAreaCodes={false}
          name={props.keyField}
          value={fieldValue?.[props.keyField]?.toString()}
          onChange={Re}
          inputProps={{
            required: props?.props?.required,
          }}
          disabled={disabledFields.includes(props.keyField)}
        />
        <InputError>
          {errorFields[props.keyField]}
        </InputError>
        </InputWrapper>
      </Box>
    )
}

Phone.defaultProps = {
    label: null,
    placeholder: 'enter value',
    helper: null,
    type: "text",
    rules: {
        validation: null,
        required: false,
        before: null
    }
  };
  
Phone.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helper: PropTypes.string,
    type: PropTypes.string,
    rules: PropTypes.any,
};

export default Phone;
